<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          style="padding: 14px 20px"
          class="capitalize"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card flat>
        <v-card-text> Reviews </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import WinScroll from "../../mixins/windowscroll";
export default {
  components: {},
  mixins: [WinScroll("position")],
  data() {
    return {
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "medics.dash" },
        },
      ],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {},
  methods: {
    menulink() {
      this.$emit("sidebar");
    },
  },
};
</script>
